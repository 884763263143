function parseBoolean (value, defaultVal) {
  if (defaultVal === undefined) {
    defaultVal = false
  }
  if (typeof(value) === 'boolean') {
    return value
  } else if (typeof(value) === 'number') {
    return Boolean(value)
  } else if (typeof(value) === 'string') {
    if (value.toLowerCase() === 'true') {
      return true
    } else if (value.toLowerCase() === 'false') {
      return false
    } else if (!isNaN(value)) {
      return Boolean(parseInt(value))
    }
  }
  // in any other case:
  return defaultVal
}

function parseJson (value, defaultVal) {
  if (defaultVal === undefined) {
    throw new Error('defaultVal is required parameter for parseJson function.')
  }
  return value ? JSON.parse(value) : defaultVal
}

export const appTitle = process.env.VUE_APP_TITLE || 'HeartBit Volunteering'
export const appFullTitle = (
  process.env.VUE_APP_FULL_TITLE ||
  'System do zarządzania wolontariatem pracowniczym HeartBit Volunteering'
)
export const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:8000/api'
export const appDomain = process.env.VUE_APP_DOMAIN || 'localhost'
export const supportEmail = process.env.SUPPORT_EMAIL ||
  'kontakt@heartbit.com.pl'
export const activitiesEnabled = parseBoolean(
  process.env.VUE_APP_ACTIVITIES_ENABLED, false
)

// Event Browser related settings
export const browserTabs = parseJson(
  process.env.VUE_APP_BROWSER_TABS, ['campaigns', 'events', 'reports']
)

// Inspirations page related settings
export const featSearchEnabled = parseBoolean(
  process.env.VUE_APP_FEAT_SEARCH_ENABLED, true
)
export const featMaterialsEnabled = parseBoolean(
  process.env.VUE_APP_FEAT_MATERIALS_ENABLED, true
)
export const featUsersEnabled = parseBoolean(
  process.env.VUE_APP_FEAT_USERS_ENABLED, true
)
export const featBeneficiariesEnabled = parseBoolean(
  process.env.VUE_APP_FEAT_BENEFICIARIES_ENABLED, true
)
export const featReportsEnabled = parseBoolean(
  process.env.VUE_APP_FEAT_REPORTS_ENABLED, true
)
export const featRatingsEnabled = parseBoolean(
  process.env.VUE_APP_FEAT_RATINGS_ENABLED, false
)

// Registration form
export const passwordFieldDescr = 
  process.env.VUE_APP_PASSWORD_FIELD_DESCR || (
    'Będziesz go używać do zalogowania się na platformie. ' +
    'Co najmniej 8 znaków, jedna duża litera oraz jedna cyfra.'
  )

// Landing page
export const landingActiveEventsEnabled = parseBoolean(
  process.env.VUE_APP_LANDING_ACTIVE_EVENTS_ENABLED, true
)
export const landingVideoEnabled = parseBoolean(
  process.env.VUE_APP_LANDING_VIDEO_ENABLED, true
)
export const landingReportsUnauthEnabled = parseBoolean(
  process.env.VUE_APP_LANDING_REPORTS_UNAUTH_ENABLED, true
)
export const landingReportsAuthEnabled = parseBoolean(
  process.env.VUE_APP_LANDING_REPORTS_AUTH_ENABLED, true
)

// Likes
export const likesEnabled = parseBoolean(
  process.env.VUE_APP_LIKES_ENABLED, true
)
export const likeableContentTypes = parseJson(
  process.env.VUE_APP_LIKEABLE_CONTENT_TYPES,
  [
    'events.event',
    'events.beneficiaryprofile',
    'activities.activity',
    'event_reports.eventreport',
    'cms.linktile'
  ]
)


// Sentry
export const sentryEnabled = parseBoolean(
  process.env.VUE_APP_SENTRY_ENABLED, false
)
export const sentryDsn = process.env.VUE_APP_SENTRY_DSN || ''
export const sentrySampleRate = process.env.VUE_APP_SENTRY_SAMPLE_RATE || 1.0
